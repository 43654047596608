<template>
    <div class="text-xs">
        <div class="no-print">
            <div class="row justify-content-center">
              <div class="col-md-6">
                    <div class="sm-form ">
                        <label for="number">Lebar laporan (1-12)</label>
                        <input type="text" id="number" min="0" max="12" name="number" class="form-control form-control-sm" placeholder="number" v-model="number" >
                    </div>
                    <hr>
                    <button type="button" @click="id_nota=0" class="btn btn-sm btn-dark float-right "><span class="typcn typcn-arrow-repeat"></span></button>
                    <button type="button" @click="prints" class="btn btn-sm btn-dark  ">Print <span class="typcn typcn-print"></span></button>
                    <hr>
              </div>
            </div>
        </div>
        <div class="row justify-content-center" v-if="id_nota!=0">
          <div :class="`col-md-${number} shadow`">
        <p class="text-center">Nama Toko</p>
        <p class="text-center">Jl. Brigjend H Hasan Basrie</p>
        <br>
        <div class="row justify-content-between">
          <div class="col-6">
            <p>{{datanya[0].nota}}</p>
          </div>
          <div class="col-6 text-right">
              {{format(datanya[0].tanggal)}}
          </div>
          <div class="col-12">
              <hr>
          </div>
          <div class="col-12">
              <table class="table table-sm table-responsive">
                  <tr>
                      <td></td>
                      <td></td>
                      <td>Admin</td>
                  </tr>
                  <tr v-for="(item, index) in transaksi" :key="index+'transaksi'">
                      <td>{{item.nama_produk}}</td>
                      <td>{{item.jumlah}} x {{item.harga}}</td>
                      <td>{{item.total}} (diskon {{item.diskon}}%)</td>
                  </tr>
                 
                  <tr class="border-none">
                      <td class="border-none"></td>
                      <td>Harga Total</td>
                      <td>{{datanya[0].total}}</td>
                  </tr>
                  <tr>
                      <td></td>
                      <td>Uang Dibayar</td>
                      <td>{{datanya[0].uang}}</td>
                  </tr>
                  <tr>
                      <td></td>
                      <td>Kembalian</td>
                      <td>{{parseInt(datanya[0].uang)-parseInt(datanya[0].total)}}</td>
                  </tr>
              </table>
          </div>
        </div>
    </div>    
    </div>
     <div v-else>
        <div class="row justify-content-center">
          <div class="col-md-6">
                <div class="sm-form ">
                    <label for="search">cari Nota</label>
                    <input type="text" id="search" name="search" class="form-control form-control-sm" placeholder="search" v-model="search" >
                </div>
                <div class="p-3 shadow rounded-lg text-xs hover:bg-blue-300 cursor-pointer mt-2" @click="ambilnota(item)" v-for="(item, index) in td" :key="index+'notas'">
                    <p>Nota : {{item.nota}}</p>
                    <p>Tanggal : {{item.tanggal}}</p>
                    
                </div>
          </div>
        </div>
    </div>
    </div>
</template>
<script>
import axios from 'axios'
import autophp from '@/plugins/autophp_kasir'
let sdb = new autophp();
export default{
    data(){
        return{
            number:6,
            total:0,
            uang:0,
            transaksi:[],
            datanya:[],
            search:'',
            notas:[],
            id_nota:0
        }
    },
     computed:{
      td(){
          let data=this.notas;
            data=data.filter(e=>{
                if(e.nota.toLowerCase().indexOf(this.search)!=-1){
                    return e
                }
            })
            return data
        }
    },
    methods: {
        format(tanggal) {
            console.log(tanggal)
              return this.$datefns.format(
                new Date(tanggal),
                "DD MMMM YYYY - HH-mm"
              );
            },
        prints(){
            window.print();
        },
        ambilnota(item){
            this.id_nota=item.id;
            this.getData();
        },
         getNota(){
          sdb.collection("app_kasir_transaksi_nota").doc().get("*","order by id").then(res => {
            this.notas=res;
              this.$forceUpdate();
          });
      },
        getData(){
            sdb.collection("app_kasir_transaksi_nota",false).doc().select(`SELECT tb3.nama_produk,tb2.harga,tb2.total,tb2.jumlah,tb2.diskon FROM app_kasir_transaksi_nota tb1 left join app_kasir_keranjang tb2 on tb1.nota=tb2.nota left join app_kasir_produk tb3 on tb3.id=tb2.id_produk where tb1.id='${this.id_nota}' order by tb1.id`).then(res => {
                this.transaksi=res;
            });
            sdb.collection("app_kasir_transaksi_nota").doc().get("*",`where id='${this.id_nota}' order by id`).then(res => {
                this.datanya=res;
            });
            
        }
    },
    // layout:'app_shop_admin',
    mounted() {
        this.getNota();
    },
}
</script>
<style scoped>
@media print {
  html,
  body {
    width: 210mm;
    height: 297mm;
  }
  .page {
    margin: 0;
    border: initial;
    border-radius: initial;
    width: initial;
    min-height: initial;
    box-shadow: initial;
    background: initial;
    page-break-after: always;
  }
  .no-print,
  .no-print * {
    display: none !important;
  }

  .table-print {
    border: 1px solid black;
  }

  .d-footer-printer-ttd{
    display:block;
    position:absolute;
    bottom:150px;
    right:100px;
  }
}
</style>